@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Fade-in animation */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
